import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      notAuth: true,
    },
  },
  {
    path: '/recovery-password/',
    name: 'RecoveryPassword',
    component: () => import('@/views/RecoveryPassword.vue'),
    meta: {
      notAuth: true,
    },
  },
  {
    path: '/change-password/',
    name: 'ChangePassword',
    component: () => import('@/views/ChangePassword.vue'),
    meta: {
      notAuth: true,
    },
  },
  {
    path: '/personal/',
    name: 'Personal',
    component: () => import('@/views/Personal/Personal.vue'),
    children: [
      {
        path: 'send-receipt/',
        component: () => import('@/views/Personal/SendReceipt.vue'),
      },
      {
        path: 'clients/',
        component: () => import('@/views/Personal/Clients.vue'),
      },
      {
        path: 'partners/',
        component: () => import('@/views/Personal/Partners.vue'),
      },
      {
        path: 'partners/edit/:id/',
        component: () => import('@/components/EditPartner.vue'),
      },
      {
        path: 'receipts/',
        component: () => import('@/views/Personal/Receipts.vue'),
      },
      {
        path: 'settings/',
        component: () => import('@/views/Personal/Settings.vue'),
      },
      {
        path: 'methodology/',
        component: () => import('@/views/Personal/Methodology.vue'),
      },
    ],
    redirect: '/personal/send-receipt/',
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((item) => item.meta.requiresAuth)) {
    if (!store.getters.getUserId) {
      next('/');
    }
  }

  if (to.matched.some((item) => item.meta.notAuth)) {
    if (store.getters.getUserId) {
      if (store.getters.getUserGroup === 'methodology') {
        next('/personal/methodology/');
      } else {
        next('/personal/');
      }
    }
  }

  next();
});

export default router;
