<template>
  <div id="app">
    <header
      class="header py-3 mb-sm-3 mb-md-5"
      :class="(!userId) ? 'bg-transparent' : ''"
    >
      <div class="container">
        <div class="row align-items-center justify-content-center justify-content-sm-between">
          <div class="col-ms-auto">
            <router-link
              class="logo"
              to="/"
            >
              <div class="logo__img">
                <img src="/img/logo.png" />
              </div>

              <div class="logo__text">
                Онлайн-школа английского
                <br>для детей и подростков
              </div>
            </router-link>
          </div>

          <template v-if="userId">
            <div class="col d-none d-sm-block"></div>

            <a class="menu__link d-none d-xl-block" href="">
              Пользователь: {{ userName }}
            </a>

            <div class="col-sm-auto d-none d-sm-block text-center">
              <a
                class="btn btn--small btn--red mt-2"
                href="#"
                @click.prevent="logout()"
              >Выход из кабинета</a>

              <a
                class="header__link-callback"
                href="#"
                @click.prevent="logout()"
              >Выход из кабинета</a>
            </div>
          </template>
        </div>
      </div>
    </header>

    <main>
      <router-view />
    </main>

    <footer class="footer">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4 order-4 order-lg-1 mt-3 mt-lg-0 text-center text-lg-left">
            <p class="mb-0">© 2020 Том Сойер</p>
          </div>
        </div>
      </div>
    </footer>

    <!-- <footer class="footer bg-transparent footer-fixed">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4 order-4 order-lg-1 mt-3 mt-lg-0 text-center text-lg-left">
            <p class="mb-0 color-purple">© 2020 Том Сойер</p>
          </div>
        </div>
      </div>
    </footer> -->
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      api: {
        path: '/bitrix/services/main/ajax.php',
        components: 'seoprotor:api',
        mode: 'class',
      },
    };
  },
  computed: {
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
    userId() {
      return this.$store.getters.getUserId;
    },
    userName() {
      return this.$store.getters.getUserName;
    },
  },
  methods: {
    getApiUrl() {
      const { protocol, hostname, port } = window.location;
      let urlAddress = `${protocol}//${hostname}`;
      if (port.length > 0) {
        urlAddress += `:${port}`;
      }

      const url = new URL(`${urlAddress}${this.api.path}`);
      url.searchParams.set('c', this.api.components);
      url.searchParams.set('mode', this.api.mode);

      return url.toString();
    },
    isAuthorized() {
      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'isAuthorized');

      axios
        .get(url)
        .then((response) => {
          const { data } = response.data;

          if (data.success) {
            this.$store.dispatch('setUserId', data.user.id);
            this.$store.dispatch('setUserName', data.user.name);
            this.$store.dispatch('setUserGroup', data.user.group);

            this.$router.push('/personal/');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    logout() {
      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'logout');

      axios
        .get(url)
        .then(() => {
          this.$store.dispatch('setUserId', null);
          this.$store.dispatch('setUserName', null);
          this.$store.dispatch('setUserGroup', null);

          this.$router.push('/');
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    const apiUrl = this.getApiUrl();
    this.$store.dispatch('setApiUrl', apiUrl);

    this.isAuthorized();
  },
};
</script>

<style lang="scss">
  .log-in {
    display: flex;
    align-items: center;
  }
  .log-in__form {
    padding-top: 0;
  }
  .tabs__list {
    justify-content: flex-start;
  }
</style>
